jQuery(document).ready(function ($) {
  $('[data-toggle-nav]').on('click', function () {
    $('body').toggleClass('mobile-nav');
  })

  $('[data-letter-select]').on('change', function (e){
    let val = e.currentTarget.value;
    const url = new URL(location.href);
    url.searchParams.set('filter', val);
    location.assign(url.search);
  })

  $('[data-nav-section-toggle]').on('click', function () {
    $(this).toggleClass('open').next('.nav-section-container').slideToggle().toggleClass('section-active');
  })

  $('[data-mobile-parent]').on('click', function () {
    $(this).toggleClass('open');
    $(this).parent().next('[data-nav-child]').slideToggle().toggleClass('section-active');
  })


})